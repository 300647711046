import $ from '@vaersaagod/tools/Dom';
import gsap from 'gsap';

export default el => {

    const $el = $(el);

    const toggle = (toggle, tween = true) => {
        const target = toggle.nextElementSibling;
        if (target.hidden) {
            target.hidden = false;
            toggle.setAttribute('aria-expanded', 'true');
            if (tween) {
                gsap.timeline()
                    .fromTo(target, {
                        height: 0
                    }, {
                        height: 'auto',
                        duration: 0.5,
                        ease: 'Quad.easeInOut'
                    }, 0)
                    .fromTo(target.firstElementChild, {
                        y: 20
                    }, {
                        y: 0,
                        duration: 0.3,
                        ease: 'Quad.easeInOut'
                    }, 0.2)
                    .fromTo(target.firstElementChild, {
                        opacity: 0
                    }, {
                        opacity: 1,
                        duration: 0.3
                    }, 0.2);
            } else {
                gsap.killTweensOf([target, target.firstElementChild]);
                gsap.set([target, target.firstElementChild], { height: 'auto', clearProps: 'y,opacity' });
            }
        } else {
            toggle.setAttribute('aria-expanded', 'false');
            if (tween) {
                gsap.timeline({
                    onComplete() {
                        target.hidden = true;
                    }
                })
                    .to(target, {
                        height: 0,
                        duration: 0.5,
                        ease: 'Quad.easeInOut'
                    }, 0)
                    .to(target.firstElementChild, {
                        y: -20,
                        duration: 0.3,
                        ease: 'Quad.easeIn'
                    }, 0.2)
                    .to(target.firstElementChild, {
                        opacity: 0,
                        duration: 0.3
                    }, 0.2);
            } else {
                gsap.killTweensOf([target, target.firstElementChild]);
                gsap.set([target, target.firstElementChild], { height: 'auto', clearProps: 'y,opacity' });
                target.hidden = true;
            }
        }
    };

    const onToggleClick = e => {
        e.preventDefault();
        toggle(e.triggerTarget);
    };

    const init = () => {
        $el.on('click', '[data-toggle]', onToggleClick);
        $el.find('[data-toggle]').attr({
            role: 'button',
            'aria-expanded': false,
            tabIndex: '0'
        }).on('keydown', e => {
            const key = e.which || e.keyCode || null;
            if (key === 13 || key === 32) {
                e.preventDefault();
                toggle(e.target);
            }
        }).css({
            cursor: 'pointer'
        }).each(toggle => {
            toggle.removeAttribute('href');
        });
        const { hash } = window.location;
        if (hash && hash.length > 1) {
            const maybeId = hash.replace('#', '');
            const target = $el.find(`#${maybeId}`).get(0);
            const targetToggle = target ? $(target).parent().find('[data-toggle]').get(0) : null;
            if (targetToggle) {
                toggle(targetToggle, false);
            }
        }
    };

    const destroy = () => {
        $el.off('click');
        $el.find('[data-toggle]').off('keydown');
    };

    return {
        init,
        destroy
    };

};
