import $ from '@vaersaagod/tools/Dom';
import Viewport from '@vaersaagod/tools/Viewport';
import debounce from 'lodash/debounce';
import gsap from 'gsap';

import Slideshow from './Slideshow';
import { loadFlickity } from '../lib/async-bundles';

export default el => {

    const $el = $(el);
    const $nav = $el.find('[data-nav]');
    const $slideshow = $el.find('[data-slideshow]');
    const $slides = $slideshow.find('[data-year]');
    const $progress = $nav.find('[data-progress]');
    const $years = $nav.find('[data-year]');

    let Flickity;

    let navSlider;

    let slideshow;
    let slideshowFlickity;
    let currentSlideshowIndex = 0;

    const setProgress = () => {
        const $year = $nav.find('[data-year].is-active');
        if (!$year.length) {
            return;
        }
        const activeYearX = Math.ceil(($year.offset().left - $year.parent().offset().left));
        const progress = activeYearX / $progress.width();
        gsap.to($progress.get(0), { duration: 0.3, scaleX: progress, ease: 'Power2.easeOut' });
    };

    const setActiveIndex = index => {
        if (index === -1 || index === currentSlideshowIndex) {
            return;
        }
        currentSlideshowIndex = index;
        const slide = $slides.get(index);
        const year = $(slide).data('year');
        $years.removeClass('is-active is-past');

        const $year = $nav.find(`[data-year="${year}"]`);
        $year.addClass('is-active');

        if (navSlider && navSlider.slides.length > 1) {
            requestAnimationFrame(() => {
                navSlider.selectCell($year.get(0));
            });
        }

        // Add the future class to any years preceeding this one
        $years.filter(node => $(node).data('year') < year).addClass('is-past');
        if (slideshowFlickity) {
            slideshowFlickity.select(index);
        }
        setProgress();
    };

    const setActiveYear = year => {
        const slide = $slides.parent().find(`[data-year="${year}"]`).get(0);
        const index = $slides.index(slide);
        setActiveIndex(index);
    };

    const maybeDisableNextPrev = () => {
        const $nextBtn = $nav.find('[data-nextbtn]');
        const $prevBtn = $nav.find('[data-prevbtn]');
        if (!navSlider) {
            $nextBtn.addClass('is-hidden');
            $prevBtn.addClass('is-hidden');
            return;
        }
        if (navSlider.slides.length <= 1) {
            $nextBtn.addClass('is-hidden');
            $prevBtn.addClass('is-hidden');
        } else {
            $nextBtn.removeClass('is-hidden');
            $prevBtn.removeClass('is-hidden');
        }
        const enableNext = navSlider.selectedIndex < navSlider.slides.length - 1;
        if (enableNext) {
            $nextBtn.removeClass('is-disabled');
        } else {
            $nextBtn.addClass('is-disabled');
        }
        const enablePrev = navSlider.selectedIndex > 0;
        if (enablePrev) {
            $prevBtn.removeClass('is-disabled');
        } else {
            $prevBtn.addClass('is-disabled');
        }
    };

    const onNavSliderScroll = () => {
        setProgress();
        requestAnimationFrame(() => {
            let sliderX = navSlider.x;
            if (sliderX > 0) {
                sliderX = 0;
            }
            gsap.set($progress.get(0), { x: sliderX });
            const { left: sliderLeft, width: sliderWidth } = navSlider.element.getBoundingClientRect();
            $years.each(year => {
                const { left: yearLeft } = year.querySelector('[data-dot]').getBoundingClientRect();
                if (Math.ceil(sliderLeft - yearLeft) > -1 || Math.floor((sliderLeft + sliderWidth) - yearLeft) < 10) {
                    year.classList.add('is-hidden');
                } else {
                    year.classList.remove('is-hidden');
                }
            });
        });
    };

    const onNavSliderDragStart = () => {
        $nav.css({
            pointerEvents: 'none'
        });
    };

    const onNavSliderDragEnd = () => {
        $nav.css({
            pointerEvents: ''
        });
    };

    const onNavSliderChange = () => {
        maybeDisableNextPrev();
    };

    const destroyNavSlider = () => {
        if (!navSlider) {
            return;
        }
        navSlider.off('scroll', onNavSliderScroll);
        navSlider.off('change', onNavSliderChange);
        navSlider.off('dragStart', onNavSliderDragStart);
        navSlider.off('dragEnd', onNavSliderDragEnd);
        navSlider.destroy();
        navSlider = null;
        const yearsContainer = $nav.find('[data-years]').get(0);
        gsap.set([yearsContainer, $progress.get(0)], { clearProps: 'all' });
        onNavSliderDragEnd();
    };

    const createNavSlider = () => {

        destroyNavSlider();

        const yearsContainer = $nav.find('[data-years]').get(0);
        const $allYears = $(yearsContainer.children);
        const { width, height } = yearsContainer.getBoundingClientRect();
        gsap.set(yearsContainer, { width, height });

        navSlider = new Flickity(yearsContainer, {
            pageDots: false,
            prevNextButtons: false,
            setGallerySize: false,
            resize: false,
            groupCells: true,
            cellAlign: 'left',
            contain: true,
            accessibility: true
        });

        navSlider.on('scroll', onNavSliderScroll);
        navSlider.on('change', onNavSliderChange);
        navSlider.on('dragStart', onNavSliderDragStart);
        navSlider.on('dragEnd', onNavSliderDragEnd);

        const $lastYear = $allYears.eq($allYears.length - 1);

        requestAnimationFrame(() => {
            const rangeWidth = ($lastYear.offset().left + $lastYear.width()) - $(yearsContainer).parent().offset().left;

            $progress.width(rangeWidth);
            onNavSliderScroll();
            setProgress();
            maybeDisableNextPrev();

            gsap.to($nav.get(0), { opacity: 1, duration: 0.3 });
        });

    };

    const onItemClick = e => {
        const $item = $(e.triggerTarget);
        const year = $item.parent('[data-year]').data('year');
        if (!year) {
            return;
        }
        setActiveYear(year);
    };

    const onSlideChange = () => {
        setActiveIndex(slideshowFlickity.selectedIndex)
    };

    const onNextBtnClick = () => {
        if (!navSlider) {
            return;
        }
        navSlider.next();
    };

    const onPrevBtnClick = () => {
        if (!navSlider) {
            return;
        }
        navSlider.previous();
    };

    const onResize = () => {
        createNavSlider();
    };

    const resizeHandler = debounce(onResize, 33, { leading: false, trailing: true });

    const init = () => {
        loadFlickity(flickity => {
            Flickity = flickity;
            createNavSlider();
            Viewport.on('resize', resizeHandler);
        });
        slideshow = new Slideshow($slideshow.get(0), {
            wrapAround: false
        }, {
            onReady() {
                slideshowFlickity = slideshow.getFlickity();
                slideshowFlickity.on('change', onSlideChange);
                $nav
                    .on('click', '[data-year] button', onItemClick)
                    .on('click', '[data-nextbtn]', onNextBtnClick)
                    .on('click', '[data-prevbtn]', onPrevBtnClick);
            }
        });
        slideshow.init();
    };

    const destroy = () => {
        if (slideshowFlickity) {
            slideshowFlickity.off('change', onSlideChange);
        }
        if (slideshow) {
            slideshow.destroy();
        }
        destroyNavSlider();
        $nav.off('click');
        Viewport.off('resize', resizeHandler);
    };

    return {
        init,
        destroy
    };

};
