import $ from '@vaersaagod/tools/Dom';
import Viewport from '@vaersaagod/tools/Viewport';

import gsap from 'gsap';
import { deferredCallback } from '@vaersaagod/tools/utils';

const tweenKey = '_revealtween';

export default root => {

    let currentScrollTop = -1;
    let direction = 'down';
    let observer;
    let $els;

    const getTween = el => {

        const data = $(el).data();

        const type = data.reveal || null;
        const delay = parseFloat(data['reveal-delay'] || 0);

        if (type === 'y') {
            const yPos = parseInt(data['reveal-y'] || 100, 10);
            return gsap.timeline({ paused: true, delay })
                .fromTo(el.children, { opacity: 0 }, { opacity: 1, ease: 'Quad.easeIn', duration: 0.5 }, 0)
                .fromTo(el.children, { y: yPos }, { y: 0, ease: 'Quint.easeOut', duration: 1.5 }, 0);
        }

        // Default is fade
        return gsap.timeline({ paused: true, delay })
            .fromTo(el, { opacity: 0 }, { opacity: 1, duration: 0.5, ease: 'Quad.easeIn' });
    };

    const onScroll = () => {
        const { scrollTop } = (root || Viewport);
        direction = scrollTop > currentScrollTop ? 'down' : 'up';
        currentScrollTop = scrollTop;
    };

    const scrollHandler = deferredCallback(onScroll);

    const init = () => {

        if ($('html').hasClass('is-live-preview')) {
            return;
        }

        onScroll();

        observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {

                const { target, isIntersecting, intersectionRatio } = entry;
                const { top } = target.getBoundingClientRect();

                const tween = $(target).data(tweenKey);

                if (isIntersecting && direction === 'down') {
                    tween.play();
                } else if (isIntersecting && direction === 'up') {
                    tween.pause(tween.duration());
                } if (!intersectionRatio && direction === 'up' && top >= Viewport.height) {
                    // Reset the effect
                    tween.pause(0);
                }
            });
        }, {
            root,
            threshold: [0, 0.25, 0.5, 0.75, 1],
            rootMargin: '0px 0px 150px 0px'
        });

        // Create waypoints
        const stageH = Viewport.height;
        $els = $(root || 'body').find('[data-reveal]:not(.js-reveal)');
        $els.each(el => {
            const tween = getTween(el);
            $(el)
                .addClass('js-reveal')
                .data(tweenKey, tween);
            observer.observe(el);
            if (el.getBoundingClientRect().top < stageH) {
                requestAnimationFrame(() => {
                    tween.play();
                });
            }
        });

        (root || window).addEventListener('scroll', scrollHandler);

    };

    const destroy = () => {
        (root || window).removeEventListener('scroll', scrollHandler);
        observer.disconnect();
        observer = null;
        if ($els.length) {
            $els.removeClass('js-reveal');
            gsap.killTweensOf($els.get());
            gsap.set($els.get(), { clearProps: 'all' });
        }
    };

    return {
        init,
        destroy
    };

};
