import $ from '@vaersaagod/tools/Dom';
import Viewport from '@vaersaagod/tools/Viewport';
import debounce from 'lodash/debounce';
import { loadFlickity } from '../lib/async-bundles';

export default (el, options = {}, props = {}) => {

    const $el = $(el);
    const $slider = $el.find('[data-slider]');
    const $slideContent = $slider.find('[data-slide] > *');

    let Flickity;
    let slideshow;
    let stageW = Viewport.width;
    let stageH = Viewport.height;

    let selectedIndex = 0;

    let touchingFlickity = false;
    let touchStartCoords;

    const maybeDisableNextPrev = () => {
        const $nextBtn = $el.find('[data-next]');
        const $prevBtn = $el.find('[data-prev]');
        if (!$nextBtn.length || !$prevBtn.length) {
            return;
        }
        if (!slideshow) {
            $nextBtn.addClass('is-hidden');
            $prevBtn.addClass('is-hidden');
            return;
        }
        if (slideshow.options.wrapAround) {
            $nextBtn.removeClass('is-hidden');
            $prevBtn.removeClass('is-hidden');
            return;
        }
        if (slideshow.slides.length <= 1) {
            $nextBtn.addClass('is-hidden');
            $prevBtn.addClass('is-hidden');
        } else {
            $nextBtn.removeClass('is-hidden');
            $prevBtn.removeClass('is-hidden');
        }
        const enableNext = slideshow.selectedIndex < slideshow.slides.length - 1;
        if (enableNext) {
            $nextBtn.removeClass('is-hidden');
        } else {
            $nextBtn.addClass('is-hidden');
        }
        const enablePrev = slideshow.selectedIndex > 0;
        if (enablePrev) {
            $prevBtn.removeClass('is-hidden');
        } else {
            $prevBtn.addClass('is-hidden');
        }
    };

    const onSlideChange = () => {
        selectedIndex = slideshow.selectedIndex;
        $el.find('[data-dot].is-active').removeClass('is-active');
        $el.find(`[data-dot="${selectedIndex}"]`).addClass('is-active');
        maybeDisableNextPrev();
    };

    const destroyFlickity = () => {
        if (!slideshow) {
            return;
        }
        slideshow.off('change');
        slideshow.destroy();
        slideshow = null;
        $slider.css({
            width: '',
            height: ''
        });
        $slideContent.css({
            paddingTop: '',
            paddingBottom: '',
            height: ''
        });
        $el.removeClass('js-has-flickity');
    };

    const initFlickity = () => {

        if (!Flickity) {
            return;
        }

        destroyFlickity();

        $el.addClass('js-has-flickity');

        let sliderHeight = $slider.height();
        const maxHeight = Math.round(stageH * 0.8);
        if (sliderHeight >= maxHeight) {
            sliderHeight = maxHeight;
            $slideContent.css({
                paddingTop: 0,
                paddingBottom: 0,
                height: `${sliderHeight}px`
            });
        }

        $slider.css({
            width: `${$slider.width()}px`,
            height: `${sliderHeight}px`
        });

        slideshow = new Flickity($slider.get(0), {
            pageDots: false,
            prevNextButtons: false,
            setGallerySize: false,
            resize: false,
            wrapAround: true,
            initialIndex: selectedIndex,
            accessibility: true,
            dragThreshold: 15,
            on: {
                dragStart: () => {
                    $slider.find('[data-slide]').css({
                        pointerEvents: 'none'
                    });
                },
                dragEnd: () => {
                    $slider.find('[data-slide]').css({
                        pointerEvents: ''
                    });
                }
            },
            ...options
        });

        slideshow.on('change', onSlideChange);

        maybeDisableNextPrev();

        if (props && props.hasOwnProperty('onReady') && props.onReady) {
            props.onReady();
        }

    };

    const next = () => {
        if (!slideshow) {
            return;
        }
        slideshow.next();
    };

    const prev = () => {
        if (!slideshow) {
            return;
        }
        slideshow.previous();
    };

    const onResize = () => {
        const vw = Viewport.width;
        const vh = Viewport.height;
        if (vw !== stageW || Math.abs(vh - stageH) >= 150) {
            stageW = vw;
            stageH = vh;
            initFlickity();
        }
    };

    const resizeHandler = debounce(onResize, 33, { leading: false, trailing: true });

    const onNextBtnClick = () => {
        next();
    };

    const onPrevBtnClick = () => {
        prev();
    };

    const onDotClick = e => {
        if (!slideshow) {
            return;
        }
        const index = parseInt($(e.triggerTarget).data('dot'), 10);
        slideshow.selectCell(index);
    };

    const onDocTouchStart = e => {
        if (e.target.closest('.flickity-slider')) {
            touchingFlickity = true;
        } else {
            touchingFlickity = false;
            return;
        }

        touchStartCoords = {
            x: e.touches[0].pageX,
            y: e.touches[0].pageY
        };
    };

    const onDocTouchMove = e => {
        if (!(touchingFlickity && e.cancelable)) {
            return;
        }

        const moveVector = {
            x: e.touches[0].pageX - touchStartCoords.x,
            y: e.touches[0].pageY - touchStartCoords.y
        };

        if (Math.abs(moveVector.x) > 7) {
            e.preventDefault();
        }
    };

    const onSlideClick = e => {
        const slideIndex = parseInt($(e.triggerTarget).data('slide'), 10);
        if (slideIndex === selectedIndex) {
            return;
        }
        if (slideIndex < selectedIndex) {
            prev();
        } else {
            next();
        }
    };

    const init = () => {

        loadFlickity(flickity => {
            Flickity = flickity;
            initFlickity();
            Viewport.on('resize', resizeHandler);
        });

        $el
            .on('click', '[data-next]', onNextBtnClick)
            .on('click', '[data-prev]', onPrevBtnClick)
            .on('click', '[data-dot]', onDotClick)
            .on('click', '[data-slide]', onSlideClick);

        document.body.addEventListener('touchstart', onDocTouchStart);
        document.body.addEventListener('touchmove', onDocTouchMove, { passive: false });
    };

    const destroy = () => {
        if (slideshow) {
            destroyFlickity();
            Viewport.off('resize', resizeHandler);
        }
        $el.off('click');
        document.body.removeEventListener('touchstart', onDocTouchStart);
        document.body.removeEventListener('touchmove', onDocTouchMove);
    };

    return {
        init,
        destroy,
        getFlickity() {
            return slideshow;
        }
    };

};
