import $ from '@vaersaagod/tools/Dom';

export default el => {

    const $el = $(el);
    let currentFilter = '';

    const setFilter = newFilter => {
        const filter = newFilter || '';
        if (filter === currentFilter) {
            return;
        }
        currentFilter = filter;
        $el.find('[data-items] [data-filters]').each(item => {
            if (filter === '' || $(item).data('filters') === currentFilter) {
                item.hidden = false;
            } else {
                item.hidden = true;
            }
        });
        $el.find('[data-filter].is-active').removeClass('is-active');
        $el.find(`[data-filter="${currentFilter}"]`).addClass('is-active');
    };

    const onFilterClick = e => {
        const $filter = $(e.triggerTarget);
        const filterValue = $filter.data('filter');
        setFilter(filterValue);
    };

    $el.on('click', 'button[data-filter]', onFilterClick);

    return {
        destroy() {
            $el.off('click');
        }
    };

};
