import $ from '@vaersaagod/tools/Dom';
import Components from "@vaersaagod/tools/Components";
import superagent from '@vaersaagod/tools/request';

import gsap from 'gsap';

export default el => {

    const $el = $(el);
    const nav = $el.find('nav').get(0);

    const changePage = url => {
        const $content = $el.find('[aria-live]');
        $el.addClass('js-is-loading');
        superagent
            .get(url)
            .then(({ status, text: html }) => {
                if (status !== 200) {
                    throw new Error();
                }
                const content = $content.get(0);
                gsap
                    .timeline()
                        .to(content, { opacity: 0, duration: 0.3 }, 'out')
                        .add(() => {
                            const $newContent =  $('<div />').append(html).find('[aria-live]');
                            Components.destroy(content);
                            $(content.firstElementChild).html($($newContent.get(0).firstElementChild).html());
                            Components.init(content);
                        }, 'change')
                        .fromTo(content, { opacity: 0 }, { opacity: 1, duration: 0.3, immediateRender: false }, 'in')
                        .fromTo(content.firstElementChild, { y: 30 }, { y: 0, duration: 0.5, ease: 'Quint.easeOut', immediateRender: false }, 'in')
                        .set([content, content.firstElementChild], { clearProps: 'all' }, 'done');
            })
            .catch(error => {
                console.error(error);
            })
            .then(() => {
                $el.removeClass('js-is-loading');
            });
    };

    const onNavClick = e => {
        e.preventDefault();
        const { href } = e.triggerTarget;
        const $activeNav = $(nav).find('[data-active]');
        const activeNavClasses = $activeNav.attr('class');
        const $newActiveNav = $(e.triggerTarget);
        $activeNav.attr('class', $newActiveNav.attr('class')).get(0).removeAttribute('data-active');
        $newActiveNav.attr('class', activeNavClasses).get(0).setAttribute('data-active', true);
        changePage(href);
    };

    const init = () => {
        if (!nav) {
            return;
        }
        $(nav).on('click', 'a', onNavClick);
    };

    const destroy = () => {
        if (!nav) {
            return;
        }
        $(nav).off('click');
    };

    return {
        init,
        destroy
    };

};
