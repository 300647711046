import $ from '@vaersaagod/tools/Dom';
import Viewport from '@vaersaagod/tools/Viewport';

import gsap from 'gsap';

export default el => {

    const $el = $(el);
    const text = $el.find('[data-text]').get(0);

    let tl;
    let duration;

    const onScroll = () => {
        const { top } = text.getBoundingClientRect();
        tl.progress(Math.max(0, Math.min((top * -1) / duration, 1)));
    };

    const scrollHandler = () => {
        requestAnimationFrame(onScroll);
    };

    const onResize = () => {
        if (tl) {
            tl.kill();
        }
        gsap.set([text, text.firstElementChild], { clearProps: 'all' });
        const height = Math.round(text.getBoundingClientRect().height);
        gsap.set(text, { height });
        gsap.set(text.firstElementChild, { position: 'fixed', height });
        tl = gsap.timeline({ paused: true })
            .fromTo(text.firstElementChild, { y: 0 }, { y: -(height * 0.2), duration: height, ease: 'none', snap: 'y' }, 0)
            .fromTo(text.firstElementChild, { opacity: 1 }, {
                opacity: 0,
                duration: height * 0.75
            }, 0);
        duration = tl.totalDuration();
        scrollHandler();
    };

    return {
        init() {
            window.addEventListener('scroll', scrollHandler);
            Viewport.on('resize', onResize);
            onResize();
        },
        destroy() {
            if (tl) {
                tl.kill();
            }
            Viewport.off('resize', onResize);
            window.removeEventListener('scroll', scrollHandler);
        }
    };
};
