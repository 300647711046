import $ from '@vaersaagod/tools/Dom';
import Viewport from '@vaersaagod/tools/Viewport';
import Dispatch from '@vaersaagod/tools/Dispatch';
import Config from '@vaersaagod/tools/Config';
import get from 'lodash/get';
import gsap from 'gsap';

import {
    PROGRAMMATIC_SCROLL_START,
    PROGRAMMATIC_SCROLL_END
} from './events';

export const clamp = (number, min, max) => Math.max(min, Math.min(number, max));

export const scrollTo = (node, opts = {}) => {

    const scrollTop = $(node).offset().top;
    const direction = Viewport.scrollTop < scrollTop ? 'down' : 'up';

    Dispatch.emit(PROGRAMMATIC_SCROLL_START, {
        target: node,
        direction
    });

    const pixelsPerSecond = 1500;
    const dist = Math.abs(Viewport.scrollTop - scrollTop);
    const baseDuration = dist / pixelsPerSecond;
    const duration = clamp(baseDuration, 0.5, 2);

    requestAnimationFrame(() => {
        gsap.to($('html').get(0), {
            duration,
            scrollTop,
            ease: 'Quad.easeInOut',
            onComplete() {
                Dispatch.emit(PROGRAMMATIC_SCROLL_END);
            },
            ...opts
        });
    });

};

export const i18n = (key, vars = {}) => {
    let value = get(Config.get('i18n'), key, key);
    Object.keys(vars).forEach(name => {
        const pattern = `{${name}}`;
        value = value.replace(new RegExp(pattern, 'g'), vars[name]);
    });
    return value;
};
