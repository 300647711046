import $ from '@vaersaagod/tools/Dom';
import Dispatch from '@vaersaagod/tools/Dispatch';
import superagent from '@vaersaagod/tools/request';
import Components from '@vaersaagod/tools/Components';
import gsap from 'gsap';
import get from 'lodash/get';
import Viewport from '@vaersaagod/tools/Viewport';

import Revealer from './Revealer';

import {
    MODAL_BEFORE_OPEN,
    MODAL_AFTER_OPEN,
    MODAL_BEFORE_CLOSE,
    MODAL_AFTER_CLOSE
} from './events';

let modalIsOpen = false;

export default url => {
    // Get the modal mount
    const $modal = $('#modal');
    const modal = $modal.get(0);
    const content = $modal.find('[data-modal-content]').get(0);

    let focusedElementBeforeOpen;
    let destroyHandler;

    let revealer;

    const closeModal = () => {
        Dispatch.emit(MODAL_BEFORE_CLOSE);
        gsap.timeline({
            onComplete() {
                Viewport.releaseTabbing(focusedElementBeforeOpen || null);
                focusedElementBeforeOpen = null;
                modal.hidden = true;
                destroyHandler();
                Dispatch.emit(MODAL_AFTER_CLOSE);
            }
        })
            .to(modal, {
                opacity: 0,
                duration: 0.3
            }, 0);
    };

    const openModal = () => {
        Dispatch.emit(MODAL_BEFORE_OPEN);
        focusedElementBeforeOpen = document.activeElement || null;
        modal.hidden = false;
        modalIsOpen = true;
        gsap.timeline({
            onComplete() {
                Dispatch.emit(MODAL_AFTER_OPEN);
            }
        })
            .fromTo(modal, {
                opacity: 0
            }, {
                opacity: 1,
                duration: 0.3
            }, 0);
        const closeBtn = $(modal).find('[data-modal-close]').get(0);
        Viewport.lockTabbing(modal, closeBtn);
    };

    const initContent = html => {
        const tl = gsap.timeline({ paused: true });
        if (modalIsOpen) {
            tl
                .to(content, { opacity: 0, duration: 0.3 }, 0)
                .add(() => {
                    $(content).html('');
                    Components.destroy(content);
                    if (revealer) {
                        revealer.destroy();
                    }
                });
        }
        tl
            .add(() => {
                content.scrollTop = 0;
                $(content).html(html);
                Components.init(content);
                revealer = new Revealer(content);
                revealer.init();
            })
            .fromTo(content, {
                opacity: 0
            }, {
                opacity: 1,
                duration: 0.3
            }, 0.3)
            .fromTo(content, {
                y: 50
            }, {
                y: 0,
                ease: 'Quint.easeOut',
                duration: 0.75
            }, 0.3);
        requestAnimationFrame(() => {
            tl.play();
        });
    };

    const loadContent = () => {
        $modal.addClass('js-is-loading');
        superagent
            .get(url)
            .then(({ status, text: html }) => {
                if (status !== 200 || !html) {
                    throw new Error();
                }
                initContent(html);
            })
            .catch(error => {
                const statusCode = get(error, 'response.statusCode');
                if (statusCode === 302) {
                    const redirectUrl = get(error, 'response.headers.x-redirect');
                    if (redirectUrl) {
                        window.location.href = redirectUrl;
                        return;
                    }
                }
                console.error(error);
            })
            .then(() => {
                $modal.removeClass('js-is-loading');
            });
    };

    const onBodyKeyUp = e => {
        const key = e.which || e.keyCode || null;
        if (key === 27) {
            closeModal();
        }
    };

    const init = () => {
        $('body').on('keyup', onBodyKeyUp);

        $(modal).on('click', '[data-modal-close]', e => {
            e.preventDefault();
            closeModal();
        });

        openModal();
        loadContent();
    };

    destroyHandler = () => {
        modalIsOpen = false;
        $('body').off('keyup', onBodyKeyUp);
        $(modal).off('click');
        Components.destroy(content);
        if (revealer) {
            revealer.destroy();
        }
        $(content).html('');
        gsap.set([modal, content], { clearProps: 'all' });
        focusedElementBeforeOpen = null;
    };

    init();

};
