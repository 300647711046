import './lib/polyfills';
import Config from '@vaersaagod/tools/Config';
import Viewport from '@vaersaagod/tools/Viewport';
import Components from '@vaersaagod/tools/Components';
import $ from '@vaersaagod/tools/Dom';
import gsap from 'gsap';

import Revealer from './lib/Revealer';
import ViewportHeight from './lib/ViewportHeight';
import Modal from './lib/Modal';
import lazySizes from './lib/lazysizes';

import { scrollTo } from './lib/helpers';

gsap.defaults({ overwrite: 'auto' });

/*
*   Bootstrap app
*
 */
const init = () => {

    Viewport.init();
    Components.init();
    Viewport.initTabbing();
    ViewportHeight.init();

    lazySizes.init();

    (new Revealer()).init();

    // Init company modal links
    const { contactIndexUrl, companyContactUrl } = Config.get();
    const contactLinksSelector = [contactIndexUrl, companyContactUrl].reduce((carry, url) => !!url ? carry.concat(`a[href^="${url}"]:not([data-component]):not([data-noajax])`) : carry, []);
    if (contactLinksSelector.length) {
        $('body').on('click', contactLinksSelector.join(','), e => {
            e.preventDefault();
            new Modal(e.triggerTarget.href);
        });
    }

    // Init scroll buttons
    $('body').on('click', 'a[data-scrollto]', e => {
        const targetId = $(e.triggerTarget).attr('href');
        if (!targetId || targetId.substring(0, 1) !== '#') {
            return true;
        }
        const target = $(targetId).get(0);
        if (!target) {
            return true;
        }
        e.preventDefault();
        scrollTo(target);
        return false;
    });

};

require('doc-ready')(init);
