/*
 *   Lazysizes config
 *
 */

window.lazySizesConfig = {
    init: false
};

//require('lazysizes/plugins/bgset/ls.bgset');

if (!window.HTMLPictureElement || document.msElementsFromPoint) {
    require('lazysizes/plugins/respimg/ls.respimg');
}

const lazySizes = require('lazysizes');

export default {
    init: () => lazySizes.init()
};
