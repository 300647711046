import $ from '@vaersaagod/tools/Dom';
import Viewport from '@vaersaagod/tools/Viewport';
import debounce from 'lodash/debounce';
import gsap from 'gsap';

export default el => {

    const $el = $(el);
    const inner = $el.find('[data-inner]').get(0);
    const text = $el.find('[data-text]').get(0);
    const image = $el.find('[data-image]').get(0);

    let tl;
    let duration;

    const onScroll = () => {
        const { top } = inner.getBoundingClientRect();
        const progress = Math.max(0, Math.min((top * -1) / duration, 1));
        tl.progress(progress);
        if (image) {
            if (progress >= 1) {
                gsap.set(image, { visibility: 'hidden' });
            } else {
                gsap.set(image, { visibility: '' });
            }
        }
    };

    const scrollHandler = () => {
        requestAnimationFrame(onScroll);
    };

    const onResize = () => {

        if (tl) {
            tl.kill();
        }

        gsap.set([inner, text, image].filter(node => !!node), { clearProps: 'all' });

        tl = gsap.timeline({ paused: true });

        let height = Math.round(inner.getBoundingClientRect().height);

        if (text) {
            gsap.set(text, { position: 'fixed', height });
        }

        if (image) {
            // Attempt to use the stretch CSS property to set the min-height of the hero. This will properly fill the available screen height on e.g. iOS, but has limited support and is impossible to device-detect
            $el.attr({
                style: 'height: -webkit-fill-available; height: -moz-available; height: stretch;'
            });
            height = Math.floor(inner.getBoundingClientRect().height);
            // If the inner container has no height at this point, it means that stretch isn't supported properly. Remove it and fall back to the viewport height set in CSS
            if (!height) {
                $el.attr('style', '');
                height = Math.round(inner.getBoundingClientRect().height);
            }
            gsap.set(image, { height: Math.round(el.getBoundingClientRect().height) });
            tl.fromTo(image, { y: 0 }, { y: -(height * 0.1), duration: height, ease: 'none', snap: 'y' }, 0);
        }
        gsap.set(inner, { height });
        if (text) {
            tl
                .fromTo(text, { y: 0 }, { y: -(height * 0.25), duration: height, ease: 'none', snap: 'y' }, 0)
                .fromTo(text, { opacity: 1 }, {
                    opacity: 0,
                    duration: height * 0.5
                }, 0);
        }
        duration = tl.totalDuration();
        scrollHandler();
    };

    const resizeHandler = debounce(onResize);

    return {
        init() {
            window.addEventListener('scroll', scrollHandler);
            Viewport.on('resize', resizeHandler);
            onResize();
        },
        destroy() {
            if (tl) {
                tl.kill();
            }
            Viewport.off('resize', resizeHandler);
            window.removeEventListener('scroll', scrollHandler);
        }
    };
};
