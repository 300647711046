import { loadChartJs } from "../lib/async-bundles";

export default (canvas, { data }) => {

    let chart;

    const init = () => {
        loadChartJs(Chart => {

            const ctx = canvas.getContext('2d');
            chart = new Chart(ctx, {
                type: 'bar',
                data,
                options: {
                    responsive: true,
                    animation: {
                        duration: 0
                    },
                    scales: {
                        yAxes: [{
                            gridLines: {
                                display: true
                            },
                            stacked: true,
                            ticks: {
                                callback: function (value, index, values) {
                                    return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ");
                                },
                                beginAtZero: true
                            }
                        }],
                        xAxes: [{
                            barPercentage: 0.4,
                            gridLines: {
                                display: false
                            },
                            stacked: true,
                            ticks: {
                                beginAtZero: true
                            }
                        }]
                    }
                }
            });

        });
    };

    return {
        init,
        destroy() {
            if (!chart) {
                return;
            }
            chart.destroy();
        }
    }

};
