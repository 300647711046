import $ from '@vaersaagod/tools/Dom';
import Viewport from "@vaersaagod/tools/Viewport";
import Dispatch from "@vaersaagod/tools/Dispatch";
import { AFTER_MENU_OPEN } from "../lib/events";

export default el => {

    const $el = $(el);
    const $toggle = $('a[aria-haspopup]');
    const $menu = $('[role="menu"]');

    let prevScrollTop = Viewport.scrollTop;
    let isOpen = false;

    const isSmall = () => ['l', 'lp', 'xl'].indexOf(Viewport.breakpoint.name) === -1;

    const setTabbable = value => {
        const selector = '[role="menu"],[role="menuitem"]';
        if (value) {
            $el.find(selector).each(item => item.removeAttribute('tabindex'));
        } else {
            $el.find(selector).attr({
                tabIndex: '-1'
            });
        }
    };

    const open = () => {
        if (isOpen) {
            return;
        }
        isOpen = true;
        $toggle.attr('aria-expanded', 'true');
        $el.addClass('js-is-open');
    };

    const close = () => {
        if (!isOpen) {
            return;
        }
        isOpen = false;
        $toggle.attr('aria-expanded', 'false');
        $el.removeClass('js-is-open');
    };

    const onAfterMenuOpen = () => {
        if (!isSmall()) {
            return;
        }
        setTabbable(true);
    };

    const onBreakpoint = () => {
        if (isSmall()) {
            close();
            setTabbable(true);
        } else {
            setTabbable(false);
        }
    };

    const onToggleClick = () => {
        if (isSmall()) {
            return;
        }
        if (!isOpen) {
            open();
        } else {
            close();
        }
    };

    const onBodyClick = e => {
        if (!isOpen || isSmall() || e.target === el || $el.contains(e.target)) {
            return;
        }
        close();
    };

    const onScroll = () => {
        const scrollTop = Viewport.scrollTop;
        if (Math.abs(scrollTop - prevScrollTop) < 5) {
            return;
        }
        prevScrollTop = scrollTop;
        close();
    };

    const init = () => {
        Viewport.on('breakpoint', onBreakpoint);
        Viewport.on('scroll', onScroll);
        Dispatch.on(AFTER_MENU_OPEN, onAfterMenuOpen);

        $toggle.attr({ role: 'button' }).get(0).removeAttribute('href');
        $toggle
            .on('click', onToggleClick)
            .on('keydown', e => {
                const key = e.which || e.keyCode || null;
                if (key === 13 || key === 32) {
                    e.preventDefault();
                    onToggleClick();
                }
            });

        $('body').on('click', onBodyClick);

        onBreakpoint();
    };

    const destroy = () => {
        $toggle.off('click keydown');
        Viewport.off('breakpoint', onBreakpoint);
        Viewport.off('scroll', onScroll);
        Dispatch.off(AFTER_MENU_OPEN, onAfterMenuOpen);
        $('body').off('click', onBodyClick);
    };

    return {
        init,
        destroy
    };

};
