import $ from '@vaersaagod/tools/Dom';
import Modal from "../lib/Modal";

export default el => {

    const onClick = e => {
        e.preventDefault();
        const href = el.href;
        new Modal(href);
    };

    $(el).on('click', onClick);

    return {
        destroy() {
            $(el).off('click');
        }
    };

};
