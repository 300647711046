import $ from '@vaersaagod/tools/Dom';
import Viewport from '@vaersaagod/tools/Viewport';
import Dispatch from '@vaersaagod/tools/Dispatch';
import gsap from 'gsap';

import {
    AFTER_MENU_OPEN,
    AFTER_MENU_CLOSE,
    PROGRAMMATIC_SCROLL_START,
    PROGRAMMATIC_SCROLL_END
} from '../lib/events';

import { clamp } from '../lib/helpers';

/**
 *
 * @param el
 * @param props
 * @returns {{init(): void, destroy(): void}}
 */
export default (el, props) => {

    const $el = $(el);
    const inner = $el.find('[data-inner]').get(0);
    const menu = $el.find('[data-menu]').get(0);
    const { menuId } = props || {};

    let { height } = inner.getBoundingClientRect();
    let currentY = 0;
    let colorTreshold = 0;
    let hiddenTreshold = 0;
    let prevScrollTop = Viewport.scrollTop;

    let menuIsOpen = false;
    let isScrolling = false;

    /**
     *
     */
    const onScroll = () => {

        let { scrollTop } = Viewport;
        if (scrollTop < 0) {
            scrollTop = 0;
        }

        const direction = prevScrollTop < scrollTop ? 'down' : 'up';
        const diff = Math.abs(prevScrollTop - scrollTop);

        let y;

        if (scrollTop <= hiddenTreshold || menuIsOpen || direction === 'up') {

            y = clamp(currentY + diff, -height, 0);

            if (y !== currentY) {

                y = 0;
                gsap.to(inner, {
                    duration: 0.5,
                    y,
                    ease: 'Power2.easeOut'
                });
            }

        } else if (!isScrolling && diff >= 3 && scrollTop > hiddenTreshold) {

            y = clamp(currentY - diff, -height, 0);
            if (y !== currentY) {

                y = -height;
                gsap.to(inner, {
                    duration: 0.5,
                    y,
                    onComplete() {
                        $el.addClass('js-scrolled');
                    }
                });
            }

        }

        if (scrollTop < colorTreshold) {
            $el.removeClass('js-scrolled');
        } else if (!(direction === 'down' && gsap.isTweening(inner))) {
            $el.addClass('js-scrolled');
        }

        currentY = y;
        prevScrollTop = scrollTop;

    };

    /**
     *
     */
    const openMenu = (tween = true) => {
        if (menuIsOpen) {
            return;
        }
        menuIsOpen = true;
        menu.hidden = false;
        $el.addClass('js-menu-open');
        Viewport.lockTabbing(el);
        onScroll();
        Dispatch.emit(AFTER_MENU_OPEN);

        const menuItems = $(menu).find('[data-item]').get();
        gsap.set([menu].concat(menuItems), { clearProps: 'all' });
        if (!tween) {
            return;
        }

        const tl = gsap.timeline()
            .fromTo(menu, { opacity: 0 }, { opacity: 1, duration: 0.5, ease: 'Cubic.easeOut' }, 0)
            .fromTo(menu, { scale: 0.75 }, { scale: 1, duration: 0.5, ease: 'Quint.easeOut' }, 0);

        if (menuItems.length) {
            tl
                .fromTo(menuItems, { y: 30 }, { y: 0, duration: 0.75, stagger: 0.05, ease: 'Quint.easeOut' }, 0.2)
                .fromTo(menuItems, { opacity: 0 }, { opacity: 1, duration: 0.5, stagger: 0.05, ease: 'Cubic.easeOut' }, 0.2);
        }
    };

    /**
     *
     * @param tween
     */
    const closeMenu = (tween = true) => {
        if (!menuIsOpen) {
            return;
        }
        menuIsOpen = false;
        if (tween) {
            const tl = gsap.timeline()
                .to(menu, { opacity: 0, duration: 0.3, onComplete() { menu.hidden = true; } }, 0);
            const menuItems = $(menu).find('[data-item]').get();
            if (menuItems.length) {
                tl.to(menuItems, { y: 20, duration: 0.3, ease: 'Sine.easeIn' }, 0);
            }
        } else {
            menu.hidden = true;
        }
        $el.removeClass('js-menu-open');
        Viewport.releaseTabbing();
        onScroll();
        Dispatch.emit(AFTER_MENU_CLOSE);
    };

    /**
     *
     * @param tween
     */
    const toggleMenu = tween => {
        if (menuIsOpen) {
            closeMenu(tween);
        } else {
            openMenu(tween);
        }
    };

    /**
     *
     */
    const onResize = () => {
        height = $(inner).height();
        hiddenTreshold = 0;
        colorTreshold = 0;
        const hero = $('[data-component="PageIntro"] [data-text], [data-component="Hero"] [data-inner]').get(0);
        if (hero) {
            colorTreshold = $(hero).height() - height;
            hiddenTreshold = colorTreshold - height;
        } else {
            colorTreshold = Viewport.height * 0.5;
        }
        onScroll();
    };

    /**
     *
     */
    const onBreakpoint = () => {
        const { name: breakpoint } = Viewport.breakpoint;
        const isSmall = ['l', 'lp', 'xl'].indexOf(breakpoint) === -1;
        if (!isSmall) {
            closeMenu(false);
        }
    };

    /**
     *
     * @param e
     */
    const onMenuToggleClick = e => {
        e.preventDefault();
        toggleMenu();
    };

    const onScrollStart = () => {
        isScrolling = true;
    };

    const onScrollEnd = () => {
        requestAnimationFrame(() => {
            isScrolling = false;
        });
    };

    /**
     *
     */
    return {
        init() {
            $el.on('click', '[data-menutoggle]', onMenuToggleClick); // TODO handle focus in too
            Viewport.on('scroll', onScroll);
            Viewport.on('resize', onResize);
            Viewport.on('breakpoint', onBreakpoint);
            Dispatch.on(PROGRAMMATIC_SCROLL_START, onScrollStart);
            Dispatch.on(PROGRAMMATIC_SCROLL_END, onScrollEnd);
            // Should menu be open?
            if (window.location.hash === `#${menuId}`) {
                requestAnimationFrame(() => {
                    openMenu(false);
                    window.location.hash = '';
                    if (window.history && window.history.replaceState) {
                        window.history.replaceState(null, document.title, `${window.location.pathname}${window.location.search}`);
                    }
                });
            }
            onResize();
            onBreakpoint();
        },
        destroy() {
            Viewport.off('scroll', onScroll);
            Viewport.off('resize', onResize);
            Viewport.off('breakpoint', onBreakpoint);
            Dispatch.off(PROGRAMMATIC_SCROLL_START, onScrollStart);
            Dispatch.off(PROGRAMMATIC_SCROLL_END, onScrollEnd);
            closeMenu(false);
            $el.off('click');
        }
    };

};
